import React from 'react';
import Slider from 'react-slick';import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slide1 from '../assets/images/header-black.jpg';
import Slide2 from '../assets/images/header-black2.png';
import Slide3 from '../assets/images/header-black3.jpg';

const Image = styled.img`
  height: 75vh;
  width: 100%;
  //overflow: hidden;
`;
const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 3000, // Set auto-scroll speed to 3 seconds
  slidesToShow: 1,
  slidesToScroll: 1,
};

const HeaderCarousel = () => {
  return (
    <Slider {...settings}>
        <Image src={Slide1} alt="Slide 1" />
        <Image src={Slide2} alt="Slide 2" />
        <Image src={Slide3} alt="Slide 3" />     
    </Slider>
  );
};

export default HeaderCarousel;
