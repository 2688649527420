import React from "react";
import styled from 'styled-components';
// import taxiDriverImage from '../assets/images/header-black2.png';
import busImage from '../assets/images/bus.png';
import clockImage from '../assets/images/clock.png';
import compassImage from '../assets/images/arrow-compass.png';
import planeImage from '../assets/images/plane.png';
import HeaderCarousel from "../components/Carousel";
import { useNavigate } from 'react-router-dom';
import Hero from "../components/Hero";

const HomePage = styled.div`
width: 100%;
//min-height: 100%;

`;
const Sectionhead = styled.section`
  margin-bottom: 20px;
  width: 100%;
  min-height: 200px;
  text-align: center;
  color: #000;
  //border-bottom: solid 2px #000;
  // border-bottom: 1px solid #ccc;
  // box-shadow: inset 0 -3px 3px -3px rgba(0, 0, 0, 0.5),
  //             inset 0 3px 3px -3px rgba(255, 255, 255, 0.8);
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  //height: 60vh;
  z-index: -1;
  //overflow: contain;
  @media screen and (max-width: 768px) {
		//padding-left: 50px;
	}
`;
const Header = styled.h1`
  font-size: 50px;
  font-weight: 700;
  color: #000;
  //color: #007185;
  
  font-family: 'Open Sans', sans-serif;
 // z-index: 1;
  @media screen and (max-width: 768px) {
		font-size: 30px;
	}
`;
const Header2 = styled.h2`
  font-size: 30px;
  font-weight: 500;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  //z-index: 1;
  @media screen and (max-width: 768px) {
		font-size: 20px;
	}
`;

const SectionHero = styled.section`
  display:flex;
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow:
    inset 0 -3px 3px -3px rgba(0, 0, 0, 0.5),
    inset 0 3px 3px -3px rgba(255, 255, 255, 0.8);
`;
const Column = styled.div`
  flex: 1;
  padding: 20px;
  //border: 1px solid #ccc;
  height: 400px;
  overflow: hidden;
  text-align: center;
`;
const ImageColumn = styled.div`
  flex: 2;
  padding: 20px;
  height: 450px;
  overflow: hidden;
`;
const Paragraph = styled.p`
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
`;

const Section = styled.section`
  text-align: center;
  margin: 20px;
  padding: 20px;
  height: 300px;
  background: #fff;
`;
const Alert = styled.div`
  text-align: center;
  margin: 20px;
  padding: 20px;
  color: white;
  font-size: 3em;
  font-weight: 700;
  background: red;
  border-radius: 5px;
  text-transform: uppercase;
`;


const ApplyButton = styled.button`
  background-color: #fe0607;
  border: 2px solid transparent;
  border-radius: 20px;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    color: #333;
  }
`;
const Home = () => {
  const navigate = useNavigate();

  const applyButtonClick = () => {
    navigate('/signup'); // Navigate to the '/signup' route
  };

  const learnButtonClick = () => {
    navigate('/services'); // Navigate to the '/services' route
  };

  
	return (
		<HomePage>
      <Sectionhead>
        <Header>
          Drive, Transport, Comfort
        </Header>  
        <Header2>
          Efficient, door-to-door transportation providing convenience and flexibility
        </Header2>
      </Sectionhead> 
      <SectionHero>
        <Column>
        <Alert>Now Hiring!!</Alert>
          <Header2>Join Prompt Xpress and Drive Your Future Forward</Header2>
          <Paragraph>
            Are you ready for a rewarding career as a taxi driver? Join PromptXpress, where you'll find more than just a job - you'll discover endless opportunities to drive your success.
          </Paragraph>
          <ApplyButton onClick={applyButtonClick}>Apply Now</ApplyButton>        </Column>
        <ImageColumn>
          <Hero />
        </ImageColumn>       

      </SectionHero>
      {/* <Section>
        <Header2>Join Prompt Xpress and Drive Your Future Forward</Header2>
        <Paragraph>
          Are you ready for a rewarding career as a taxi driver? Join PromptXpress, where you'll find more than just a job - you'll discover endless opportunities to drive your success.
        </Paragraph>
        <ApplyButton onClick={applyButtonClick}>Apply Now</ApplyButton>
      </Section> */}

      {/* <Section>
        <h2>Section 4</h2>

      </Section>  */}
		</HomePage>
	);
};

export default Home;
