
// Filename - App.js
 
import React from "react";
import styled from "styled-components";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Signup from "./pages/Signup";
import Contact from "./pages/Contact";
 
export const Main = styled.main`
  min-height: calc(100vh - 150px);
  min-width: 100vh;
`;
function App() {
  return (
    <Router>
      <Navbar />
      <Main>
        <Routes>        
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </Main>
      <Footer />
    </Router>
  );
}
 
export default App;
