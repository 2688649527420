import React from 'react';
import styled from 'styled-components';
import Signupform from '../components/Signupform';

const RecruitmentPage = styled.div`
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  h1 {
    color: #333;
  }

  h2 {
    color: #444;
    margin-top: 20px;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  strong {
    font-weight: bold;
  }
`;

const Signup = () => {
	
  return (
    <RecruitmentPage>
      <h1>Join PromptXpress and Drive Your Future Forward</h1>
      <p>
        Are you ready for a rewarding career as a taxi driver? Join PromptXpress, where you'll find more than just a job - you'll discover endless opportunities to drive your success.
      </p>

      <h2>Why Drive with PromptXpress?</h2>
      <ul>
        <li>
          <strong>Flexibility:</strong> Set your own schedule and work when it suits you.
        </li>
        <li>
          <strong>Earn Competitive Income:</strong> Get paid for every ride you provide.
        </li>
        <li>
          <strong>Supportive Community:</strong> Join a community of drivers who share your passion.
        </li>
        <li>
          <strong>Safety First:</strong> Your safety and well-being are our top priorities.
        </li>
      </ul>

      <h2>Requirements:</h2>
      <ul>
        <li>Valid driver's license</li>
        <li>Clean driving record</li>
        <li>Excellent customer service skills</li>
        <li>A reliable vehicle</li>
      </ul>

      <h2>How to Apply:</h2>
      <p>
        Ready to embark on a journey with PromptXpress? Apply today by submitting your application through our online portal.
      </p>
      <p>
        Take the first step towards an exciting career and start driving your future forward with PromptXpress!
      </p>
	  <Signupform />
    </RecruitmentPage>
  );
};

export default Signup;
