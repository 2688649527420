import React from 'react';
import Slider from 'react-slick';import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';

import busImage from '../assets/images/bus.png';
import clockImage from '../assets/images/clock.png';
import compassImage from '../assets/images/arrow-compass.png';
import planeImage from '../assets/images/plane.png';

import Slide1 from '../assets/images/busstop.jpg';
import Slide2 from '../assets/images/school.jpg';
import Slide3 from '../assets/images/airport.jpg';
import Slide4 from '../assets/images/header-black3.jpg';

const Icon = styled.img`
  width: 70px;
  height: 70px;
  padding: 10px 50px 0 50px;
`;

const Info = styled.div` 
  position: relative;
  flex: 1;
  margin: 10px;
  overflow: hidden;
  width: 30%;
  height: 400px;
  padding: 20px 80px 20px 20px;
  background: #ccc;
  border-radius: 5px;
  opacity: 0.7;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65px; /* Adjust the width of the angled border */
    opacity: 0.7;
    background-color: #314e69; /* Set the background color of the border */
    transform: skewX(-20deg); /* Adjust the angle of the border */
  }
`;
const Bar = styled.div`
  display: flex;
  ${(props) => props.backgroundImage && `background-image: url(${props.backgroundImage});`}
  background-size: 'cover';
  background-position: 'center';
`;
const LearnButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 20px;
  color: #000;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    //background-color: #fe0607;
    color: #fff;
  }
`;
const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 3000, // Set auto-scroll speed to 3 seconds
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Hero = () => {
  const navigate = useNavigate();

  const applyButtonClick = () => {
    navigate('/signup'); // Navigate to the '/signup' route
  };

  const learnButtonClick = () => {
    navigate('/services'); // Navigate to the '/services' route
  };


  const boldTextStyle = {
    fontWeight: 'bold',
  };
  const italicTextStyle = {
    fontStyle: 'italic',
  };

  return (
    <Slider {...settings}>
        <Bar backgroundImage={Slide1}>
          <Info>          
            <Icon src={clockImage} alt="Clock" /> 
            <p style={boldTextStyle}>
                Scheduled Pickup and Drop-offs
            </p> 
            <p style={italicTextStyle}> 
              This service allows passengers to pre-arrange their taxi pickups and drop-offs at specific times and locations. It offers convenience for individuals who need reliable transportation for regular commuting, appointments, or events.
            </p>  
            <LearnButton onClick={learnButtonClick}>Learn more..</LearnButton>
          </Info>
        </Bar>        
        <Bar backgroundImage={Slide2}>
          <Info>          
            <Icon src={busImage} alt="Bus" /> 
            <p style={boldTextStyle}>
              School Pickup and Drop-offs
            </p> 
            <p style={italicTextStyle}>
              Designed for students, this service ensures safe and timely transportation to and from school. It provides parents peace of mind and a convenient solution for their children's transportation needs.
            </p>
            <LearnButton onClick={learnButtonClick}>Learn more..</LearnButton>
          </Info>
        </Bar>   
        <Bar backgroundImage={Slide3}>
          <Info>          
            <Icon src={planeImage} alt="Plane" /> 
            <p style={boldTextStyle}>
              Airport/Cruise Pickup and Drop-offs
            </p> 
            <p style={italicTextStyle}>
              Tailored for travelers, this service facilitates transportation to and from airports or cruise terminals. It offers a hassle-free option for individuals or groups, ensuring timely arrivals and departures for flights or cruises.
            </p>
            <LearnButton onClick={learnButtonClick}>Learn more..</LearnButton>
          </Info>
        </Bar>   
        <Bar backgroundImage={Slide4}>
          <Info>          
            <Icon src={busImage} alt="Clock" /> 
            <p style={boldTextStyle}>
                Bus Pickup and Drop-offs
            </p> 
            <p style={italicTextStyle}>
              Designed for students, this service ensures safe and timely transportation to and from school. It provides parents peace of mind and a convenient solution for their children's transportation needs.
            </p>
            <LearnButton onClick={learnButtonClick}>Learn more..</LearnButton>
          </Info>
        </Bar>   

    </Slider>
  );
};

export default Hero;
