import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../assets/images/logo-white.png';

export const Nav = styled.nav`
  background: #314e69;
  height: 55px;
  min-height: -55px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;// calc((100vw - 1000px) / 2);
  z-index: 12;
  //border-bottom: 2px solid #c9c9c9;
`;

export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #fe0607;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: relative;
    margin: auto;
    //transform: translate(-100%, 75%);
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const LogoImage = styled.img`
  margin: 0 30px;
  width: 100px;
  height: 60px;
`;

const MenuToggle = styled.div`
  display: none;
  cursor: pointer;
  width: 50px;
  padding: 5px;
  font-size: 30px;
  font-weight: 400;
  @media screen and (max-width: 786px) {
    display: block;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;

  @media screen and (max-width: 786px) {
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 60px;
    background-color: #fff;
    width: 100%;
    z-index: 1000;
    padding-left: 0;
  }
`;
const SignInButton = styled.button`
  background-color: #fe0607;
  border: 2px solid transparent;
  border-radius: 20px;
  color: #fff;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #ffff;
    color: #000;
  }
`;
const NavLinkItem = styled.li`
  margin-right: 20px;
  @media screen and (max-width: 786px) {
    margin: 10px 0;
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  align-items: left;
  order: 1;
  @media screen and (max-width: 786px) {
    order: 2;
  }
`;

export const NavLinksContainer = styled.div`
  display: flex;
  align-items: center;
  order: 2;  
  @media screen and (max-width: 786px) {
    order: 1;
  }
`;

export const SignInButtonContainer = styled.div`
  display: flex;
  align-items: center;
  order: 3;
  padding-right: 20px;
  margin-left: auto;
`;
const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const closeMenu = () => {
    setIsActive(false);
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'setmore_script';
    script.type = 'text/javascript';
    script.src = 'https://storage.googleapis.com/fullintegration-live/webComponentAppListing/Container/setmoreIframeLive.js';
    document.head.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <Nav>
      <LogoContainer>
        <LogoImage src={Logo} />
      </LogoContainer>
      <NavLinksContainer>
        <MenuToggle onClick={toggleMenu}>
          <Bars />
        </MenuToggle>
        <NavLinks isActive={isActive}>
          <NavLinkItem> <NavLink to="/" onClick={closeMenu} activeStyle> Home </NavLink> </NavLinkItem>
          <NavLinkItem> <NavLink to="/services" onClick={closeMenu} activeStyle> Services </NavLink> </NavLinkItem>
          <NavLinkItem> <NavLink to="/about" onClick={closeMenu} activeStyle> About </NavLink> </NavLinkItem>
          <NavLinkItem> <NavLink to="/contact" onClick={closeMenu} activeStyle> Contact Us </NavLink> </NavLinkItem>
          <NavLinkItem> <NavLink to="/signup" onClick={closeMenu} activeStyle> Become a Driver </NavLink> </NavLinkItem>
        </NavLinks>
      </NavLinksContainer>
      <SignInButtonContainer>
        <a style={{ float: 'none' }} id="Setmore_button_iframe" href="https://booking.setmore.com/scheduleappointment/d6fb9a51-ad32-49fb-aefe-103a0656ba82">
          <img border="none" src="https://storage.googleapis.com/full-assets/setmore/images/1.0/Settings/book-now-blue.svg" alt="Book an appointment with John King using Setmore"/>
        </a>
   {/* <SignInButton to="/signin">Bookings</SignInButton>  */}
      </SignInButtonContainer>
    </Nav>
  );
};

export default Navbar;
