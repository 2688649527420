
import React from "react";
import styled from "styled-components";


export const FootMenu = styled.footer`
  clear: both;
  position: relative;
  height: 55px;
  min-height: -55px;
  width: 100%;
 //background-color: #2f2f2f;
  background: #314e69;
  color: #fff;
  border-top: 2px solid #ccc;
  text-align: center;
	@media screen and (max-width: 768px) {
		//display: none;
	}
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
	return (
    <FootMenu>
        <p>© 2017 - {currentYear} Prompt Xpress. All rights reserved.</p>
    </FootMenu>
	);
};

export default Footer;
